<template>
  <b-card title="Novo Estado">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <!-- Código -->
          <b-col md="4">
            <b-form-group
              label="Código"
              label-for="h-codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-form-input
                  id="h-codigo"
                  v-model="estado.codigo"
                  placeholder="Código"
                  maxlength="15"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="8">
            <b-form-group
              label="Descrição"
              label-for="h-descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="h-descricao"
                  v-model="estado.descricao"
                  placeholder="Descrição"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group
              label="Disponível"
              label-for="disponivel"
              label-class="mb-1"
            >
              <b-form-checkbox-group
                id="disponivel"
                v-model="arrayDisponivel"
                :options="disponivelContratosOptions"
                @change="alteraEstados()"
              />
            </b-form-group>

          </b-col>
         
          
        
          <b-col
            cols="12"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Contrato"
              label-for="contrato"
              label-class="mb-1"
            >
              <b-form-radio-group
                v-if="estado.disponivelContratos===0"
                id="contrato"
                v-model="estadoContrato"
                :options="opcoesContrato"
                value="1"
                disabled
              />
              <b-form-radio-group
                v-else
                id="contrato"
                v-model="estadoContrato"
                :options="opcoesContrato"
                value="1"
              />
            </b-form-group>
          </b-col>
          <b-col
          
            cols="12"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Serviço"
              label-for="servico"
              label-class="mb-1"
            >
              <b-form-radio-group
                v-if="estado.disponivelServicos===0"
                id="servico"
                v-model="estadoServico"
                :options="opcoesServico"
                value="1"
                disabled
              />
              <b-form-radio-group
                v-else
                id="servico"
                v-model="estadoServico"
                :options="opcoesServico"
                value="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
            lg="1"
          >
            <b-form-group
              label="Processar"
              label-for="processaFaturacao"
              label-class="mb-1"
            >
              <b-form-checkbox-group
              v-if="estado.disponivelServicos===0"
                id="processaFaturacao"
                v-model="arrayFaturacao"
                :options="faturacaoOptions"
                @change="alteraEstadoFaturacao()"
                disabled
              />
              <b-form-checkbox-group
              v-else
                id="processaFaturacao"
                v-model="arrayFaturacao"
                :options="faturacaoOptions"
                @change="alteraEstadoFaturacao()"
                
              
              />
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormCheckboxGroup,BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      estadoServico: '',
      estadoContrato: '',
      arrayDisponivel: [],
      arrayFaturacao: [],
      estado: {
        codigo: '',
        descricao: '',
        disponivelServicos: 0,
        disponivelContratos: 0,
        servicoPendente: 0,
        servicoFechado: 0,
        contratoAtivo: 0,
        contratoTerminado: 0,
        processaFaturacao: 0,
      },
    }
  },
  setup() {
    const opcoesServico = [
      { text: 'Pendente', value: 'Pendente' },
      { text: 'Fechado', value: 'Fechado' },
    ]

    const opcoesContrato = [
      { text: 'Ativo', value: 'Ativo' },
      { text: 'Terminado', value: 'Terminado' },
    ]
    const disponivelContratosOptions = ['Contrato','Serviços']
    const faturacaoOptions = ['Faturação']
    return {
      opcoesServico,
      opcoesContrato,
      disponivelContratosOptions,
      faturacaoOptions,
    }
  },
  created() {
    localize('pt', pt)
  },
  methods: {
    alteraEstados() {
      this.estado.disponivelContratos = 0
      this.estado.disponivelServicos = 0
      for (let i = 0; i < this.arrayDisponivel.length; i += 1) {
        if (this.arrayDisponivel[i] === 'Contrato') {
          this.estado.disponivelContratos = 1
        }
        if (this.arrayDisponivel[i] === 'Serviços') {
          this.estado.disponivelServicos = 1
        }
      }
    },
    alteraEstadoFaturacao() {
      this.estado.processaFaturacao = 0
      for (let i = 0; i < this.arrayFaturacao.length; i += 1) {
        if (this.arrayFaturacao[i] === 'Faturação') {
          this.estado.processaFaturacao = 1
        }
      }
    },
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarEstado()
        }
      })
    },
    guardarEstado() {
      if (this.estado.disponivelServicos === 1) {
        if (this.estadoServico === 'Pendente') {
          this.estado.servicoPendente = 1
          this.estado.servicoFechado = 0
        } else if (this.estadoServico === 'Fechado') {
          this.estado.servicoPendente = 0
          this.estado.servicoFechado = 1
        }
      } else if (this.estado.disponivelServicos === 0) {
        this.estado.servicoPendente = 0
        this.estado.servicoFechado = 0
        this.estado.processaFaturacao = 0
      }

      if (this.estado.disponivelContratos === 1) {
        if (this.estadoContrato === 'Ativo') {
          this.estado.contratoAtivo = 1
          this.estado.contratoTerminado = 0
        } else if (this.estadoContrato === 'Terminado') {
          this.estado.contratoAtivo = 0
          this.estado.contratoTerminado = 1
        }
      } else if (this.estado.disponivelContratos === 0) {
        this.estado.contratoAtivo = 0
        this.estado.contratoTerminado = 0
      }
      axios.post('/api/v1/estados', this.estado)
        .then(this.showToast('success', 'CheckIcon'))
    },

    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>
